import "../scss/blog.scss";

/**
 * Comments on blog posts
 * @page /blog
 */
const commentRespond = document.querySelector(".comment-respond");
const addComment = document.querySelector(".add-comment");
const commentReply = document.querySelectorAll(".comment-reply-link");
if (commentRespond) {
    addComment!.addEventListener("click", () => {
        commentRespond.classList.add("show-comments");
        addComment!.classList.add("hide-this");
    });

    if (commentReply.length > 0) {
        for (const reply of commentReply) {
            reply.addEventListener("click", () => {
                commentRespond.classList.add("show-comments");
                addComment!.classList.add("hide-this");
            });
        }

        document.getElementById("cancel-comment-reply-link")!.addEventListener("click", () => {
            commentRespond.classList.remove("show-comments");
            addComment!.classList.remove("hide-this");
        });
    }
}

/**
 * This uses the native share dialog
 */
function setupShare() {
    const title = (document.querySelector(".article-title") as HTMLElement).textContent;
    const url = window.location.href;

    const shareButton = document.querySelector(".social-share-button") as HTMLButtonElement;
    const legacyShare = document.querySelector(".social-hover") as HTMLElement;

    const shareData = {
        title,
        text: "View Blog Post",
        url,
    };

    shareButton.addEventListener("click", async () => {
        try {
            await (navigator as any).share(shareData);
        } catch (e) {
            throw new Error(e);
        }
    });

    legacyShare.style.display = "none";
    shareButton.style.display = "block";
}

if ("share" in navigator) {
    setupShare();
}
